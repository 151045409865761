.snake-container {
    text-align: center;
    background-color: black;
    outline-color: white;
    outline-style: solid;
    outline-width: 5px;
}

.game-border {
    -webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.75);
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
}

.point-bar {
    margin: 5px auto;
    text-align: right;
}

.game-splash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000000d5;
}

.game-splash div, button {
    margin: 20px 0;
}

.game-splash div {
    font-size: 60px;
    color: rgb(0, 0, 0);
}

.game-splash button {
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: #575858;
    color: white;
    font-size: 25px;
    transition: 0.5s;
}

.game-splash button:hover {
    background-color: black;
    cursor: pointer;
    color: black;
}

.snake-mobile-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.snake-mobile-buttons div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.snake-mobile-buttons div button {
    margin: 5px 100px;
    padding: 8px 20px;
    border-radius: 0;
    border: 1px solid black;
}