:root {
  --primary: #000000;
  --secondary: #fff;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@font-face {
  font-family: 'Ubuntu-Light';
  src: local('Ubuntu-Light'), url(./assets/fonts/Ubuntu/Ubuntu-Light.ttf) format('trueType');
}


@font-face {
  font-family: 'Ubuntu-Medium';
  src: local('Ubuntu-Medium'), url(./assets/fonts/Ubuntu/Ubuntu-Medium.ttf) format('trueType');
}

@font-face {
  font-family: 'Ubuntu-Bold';
  src: local('Ubuntu-Bold'), url(./assets/fonts/Ubuntu/Ubuntu-Bold.ttf) format('trueType');
}


@font-face {
  font-family: 'Thesignature';
  src: local('Thesignature'), url(./assets/fonts/Thesignature/Thesignature.ttf) format('trueType');
}
